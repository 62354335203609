<template>
	<div style="width: 700px; margin: 0 auto;">.
		<div style="height: 1104px; background-color: #ccc; " v-if="planInfo.pmid==2">

			<div style="font-size: 20px; font-weight: bold; text-align: center; padding-top: 150px;">
				{{planInfo.plan_name}}

			</div>

			<div style="margin-top: 400px;">
				<div class="flexBetween" style="text-align: center; padding: 0 50px ;">
					<div style="width: 300px;">
						<div class="boxitem">
							<img :src="'./img/bx_bg.png'">
							<div class="boxdesc">
								需完成总人数：{{gkInfo.stu_total}}
							</div>

						</div>
						<div class="boxitem">
							<img :src="'./img/bx_bg.png'">
							<div class="boxdesc">
								已完成人数：{{gkInfo.finish_ok}}
							</div>

						</div>
						<div class="boxitem">
							<img :src="'./img/bx_bg.png'">
							<div class="boxdesc">
								未完成人数：{{gkInfo.finish_not}}
							</div>

						</div>
					</div>
					<div style="flex-grow: 1; text-align: center;">
						<img src="../../../../public/img/plan_001.jpg" alt="" style="max-width: 100%;max-height: 100%;">
					</div>
					<div style="width: 300px;">
						<div class="boxitem">
							<img :src="'./img/bx_bg.png'">
							<div class="boxdesc">

								<div style="line-height: 30px;">
									小程序完成人数：{{gkInfo.type_mini}}人
									<br />
									占比{{gkInfo.type_mini_p}}%
								</div>

							</div>

						</div>
						<div class="boxitem">
							<img :src="'./img/bx_bg.png'">
							<div class="boxdesc">

								<div style="line-height: 30px;">
									需完成总人数：{{gkInfo.type_sms}}人
									<br />
									占比{{gkInfo.type_sms_p}}%
									<br />
								</div>
							</div>
						</div>
						<div class="boxitem">
							<img :src="'./img/bx_bg.png'">
							<div class="boxdesc">
								<div style="line-height: 30px;">
									需完成总人数：{{gkInfo.type_mail}}人
									<br />
									占比{{gkInfo.type_mail_p}}%
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-for="(group,i) in tjTmlist " :key="i">
			<div style="text-align: center; font-weight: bold; color: orangered; font-size: 50px;">
				{{group.title}}
			</div>
			<div v-for="(tm,idx) in group.data" :key="idx" v-if="tm.tm_type!='wenda'&&tm.tm_type!='tiankong'"
				style=" height: auto; width: 100%; display: inline-block; vertical-align: middle; padding-top: 20px; overflow: hidden;">
				<div style="font-size: 18px; font-weight: bold;">
					{{idx+1}}、{{tm.tm_title}}

				</div>
				<div>
					{{tm.desc}}
				</div>
				<div :id="'chart_'+tm.id" style="height: 250px;">

				</div>
				<div :id="'chart_jie_'+tm.id"
					style="height: 500px; width: 200%; transform: scale(0.6,0.6) translateX(-5%) ; transform-origin: left top;">

				</div>
				<div  style="margin-top: -250px;">
					<div style="height: 250px;" v-for="(gyear,idx) in tm.gyears" :key="gyear.gyear+'_'+tm.id"
						:id="'chart_year_'+gyear.gyear+'_'+tm.id">
					
					</div>
				</div>
				<div  style="">
					<div style="height: 250px;" v-for="(gyear,idx) in tm.tm_content" :key="gyear.xxid" :id="'chart_'+tm.id+'_'+gyear.xxid">
					
					</div>
				</div>
			</div>
		</div>


		<div class="laodok" v-if="laodok">

		</div>
	</div>
</template>

<script>
	export default {
		components: {},
		data() {
			return {
				plan_id: 0,
				ivst_id: 0,
				planInfo: {},
				tjTmlist: [],
				gkInfo: {},
				laodok: false,
				AllStudents: [],
				StuDic: {}
			}
		},
		mounted() {
			this.plan_id = this.$route.query.plan_id
			this.getGK()
		},
		methods: {


			getGK() {
				this.$http.post("api/ivst_plan", {
					plan_id: this.plan_id,
				}).then(res => {
					this.planInfo = res.data
				})
				this.$http.post("api/get_ivst_tj_gk", {
					plan_id: this.plan_id
				}).then(res => {

					let gkInfo = res.data;
					if (gkInfo.finish_ok > 0) {
						gkInfo.type_mini_p = parseInt(gkInfo.type_mini / gkInfo.finish_ok * 10000) / 100
						gkInfo.type_sms_p = parseInt(gkInfo.type_sms / gkInfo.finish_ok * 10000) / 100
						gkInfo.type_mail_p = parseInt(gkInfo.type_mail / gkInfo.finish_ok * 10000) / 100

					} else {
						gkInfo.type_mini_p = 0
						gkInfo.type_sms_p = 0
						gkInfo.type_mail_p = 0
					}
					gkInfo.percent = parseInt(gkInfo.finish_ok / gkInfo.stu_total * 10000) / 100


					this.gkInfo = gkInfo

					this.$http.post("api/njwx_stu_list_years", {}).then(res => {
						let gyear = [],
							gyeardic = {},
							StuDic = {}
						for (let y of res.data) {
							if (!gyeardic[y.gyear]) {
								gyeardic[y.gyear] = [y]
							} else {
								gyeardic[y.gyear].push(y)
							}
							StuDic[y.openid] = y.gyear
						}

						//console.log(StuDic)
						this.StuDic = StuDic
						this.AllStudents = gyeardic

						this.getTongji()
					})

				})
			},
			getTongji() {
				this.$http.post("api/get_ivst_tj_tm", {
					plan_id: this.plan_id,
				}).then(res => {
					for (let item of res.data) {
						let arr = item.tm_content.sort(function(a, b) {
							return a.choose_count - b.choose_count
						})
						let count = 0;
						for (let k in item.students) {
							count++
						}



						item.tm_content = arr;
						item.hasXX = false;
						if (item.tm_type.indexOf("radio") >= 0 || item.tm_type.indexOf("checkbox") >= 0) {
							item.hasXX = true
							item.desc = "位列前三位的依次是 ";
							for (let i = arr.length - 1; i > arr.length - 4; i--) {
								if (i > 0) {
									item.desc += arr[i].title + "（" + parseInt((arr[i].choose_count / this.gkInfo
										.finish_ok * 10000) / 100) + "%） "
								}
							}
						} else {
							item.desc = item.answers.join(";")
						}

					}
					//界别
					let jiebie = [];
					for (let k in this.AllStudents) {
						jiebie.push({
							gyear: k,
							count: 0
						})
					}
					//分组

					let firstTm = res.data[0]
					let fenlei = []
					let first = {
						title: "您现在的情况",
						data: [firstTm]
					}

					fenlei.push(first)

					fenlei.push({
						title: "所有人",
						data: []
					})

					for (let tm of firstTm.tm_content) {
						let t = {
							title: tm.title,
							data: [],

						}
						fenlei.push(t)
					}


					for (let tm of res.data) {
						tm.gyears = JSON.parse(JSON.stringify(jiebie))
						for (let k in tm.students) {
							let year = this.StuDic[k]
							if (year) {
								for (let gyear of tm.gyears) //gyears:JSON.parse(JSON.stringify(jiebie))
								{

									if (gyear.gyear == year) {
										gyear.count++

									}
								}
							}
						}

						let ok = false
						for (let fl of fenlei) {

							if (tm.tm_title.indexOf(fl.title) >= 0) {
								if (tm.tm_title == "您现在的情况") {
									//console.log(tm.tm_title)
								}

								if (tm.tm_type.indexOf("radio") >= 0 || tm.tm_type.indexOf("checkbox") >= 0) {
									for (let xx of tm.tm_content) {
										xx.gyears = JSON.parse(JSON.stringify(jiebie))


										for (let openid of xx.students) {

											let year = this.StuDic[openid]
											if (year) {
												for (let gyear of xx
														.gyears) //gyears:JSON.parse(JSON.stringify(jiebie))
												{

													if (gyear.gyear == year) {
														gyear.count++

													}
												}
											}
										}
									}

								}

								fl.data.push(tm)



							}
							if (ok) {
								break
							}
						}
					}



					//console.log(fenlei)
					fenlei[0].data=[fenlei[0].data[0]]
					this.tjTmlist = fenlei

					this.$nextTick(() => {
						setTimeout(() => {
							for (let i = 0; i < this.tjTmlist.length; i++) {
								for (let j = 0; j < this.tjTmlist[i].data.length; j++) {
									this.initChartTM(i, j)
									this.initCharsJie(i, j)
									this.initChartYears(i, j)
									this.initChartXXYears(i, j)
									
								}
							}
							setTimeout(() => {
								this.laodok = true
							}, 500)
						}, 300)
					})

				})
			},
			initChartTM(i, j) {
				let colorList = ['#73c0de', '#91cc75', '#fac858', '#ee6666', '#3ba272',
					'#fc8452', '#9a60b4', '#ea7ccc', '#5470c6'
				]

				let tmdata = this.tjTmlist[i].data[j]

				if (tmdata.hasXX) {

					let data = tmdata.tm_content
					let myChart1 = this.$echarts.init(document.getElementById('chart_' + tmdata.id))



					let option = {

						tooltip: {
							trigger: 'axis',
							axisPointer: {
								type: 'shadow'
							}
						},

						grid: {
							left: '3%',
							right: '4%',
							bottom: '3%',
							containLabel: true
						},
						xAxis: {
							type: 'value',
							boundaryGap: [0, 0.01]
						},
						yAxis: {
							type: 'category',
							data: data.map(e => {
								return e.title
							})
						},
						series: [{
								name: '选择人数',
								type: 'bar',
								barWidth: 15,
								itemStyle: {
									color: function(p) {
										return colorList[p.dataIndex]
									}
								},
								data: data.map(e => {
									return e.choose_count
								})
							},

						]
					};
					myChart1.setOption(option)




				}
			},
			initChartYears(i, j) {
				let colorList = ['#73c0de', '#91cc75', '#fac858', '#ee6666', '#3ba272',
					'#fc8452', '#9a60b4', '#ea7ccc', '#5470c6'
				]

				let tmdata = this.tjTmlist[i].data[j]

				if (tmdata.hasXX) {
					let data = tmdata.tm_content
					for (let year of tmdata.gyears) {

						let myChart1 = this.$echarts.init(document.getElementById('chart_year_' + year.gyear + "_" + tmdata
							.id))

						let arr = []
						for (let xx of data) {
							for (let sy of xx.gyears) {
								if (sy.gyear == year.gyear) {
									arr.push(sy.count)
								}
							}
						}

						let option = {
							title: {
								text: year.gyear + "届"
							},
							tooltip: {
								trigger: 'axis',
								axisPointer: {
									type: 'shadow'
								}
							},
							grid: {
								left: '3%',
								right: '4%',
								bottom: '3%',
								containLabel: true
							},
							xAxis: [{
								type: 'category',
								data: data.map(e => {
									return e.title
								}).reverse(),
								axisTick: {
									alignWithLabel: true
								},
								axisLabel: { interval: 0, rotate: 30 }
							}],
							yAxis: [{
								type: 'value'
							}],
							series: [{
								name: '选择人数',
								type: 'bar',
								barWidth: 15,
								itemStyle: {
									color: function(p) {
										return colorList[p.dataIndex]
									}
								},
								data: arr.reverse()
							}]
						};



						myChart1.setOption(option)


						
					}




				}
			},
			initChartXXYears(i, j) {
				let colorList = ['#73c0de', '#91cc75', '#fac858', '#ee6666', '#3ba272',
					'#fc8452', '#9a60b4', '#ea7ccc', '#5470c6'
				]
			
				let tmdata = this.tjTmlist[i].data[j]
			
				if (tmdata.hasXX) {
					let data = tmdata.tm_content
					for (let xx of tmdata.tm_content) {
						
						let myChart1 = this.$echarts.init(document.getElementById('chart_'+tmdata.id+"_" + xx.xxid))
						
						let arr =  xx.gyears.map(e=>{ return e.count})
						
						let option = {
							title: {
								text: "选项："+xx.title
							},
							tooltip: {
								trigger: 'axis',
								axisPointer: {
									type: 'shadow'
								}
							},
							grid: {
								left: '3%',
								right: '4%',
								bottom: '3%',
								containLabel: true
							},
							xAxis: [{
								type: 'category',
								data: tmdata.gyears.map(e => {
									return e.gyear
								}),
								axisTick: {
									alignWithLabel: true
								},
								
							}],
							yAxis: [{
								type: 'value'
							}],
							series: [{
								name: '选择人数',
								type: 'bar',
								barWidth: 15,
								itemStyle: {
									color: function(p) {
										return colorList[p.dataIndex]
									}
								},
								data: arr
							}]
						};
			
			
			
						myChart1.setOption(option)
			

						
					}
			
			
			
			
				}
			},

			initCharsJie(i, j) {
				let colorList = ['#73c0de', '#91cc75', '#fac858', '#ee6666', '#3ba272',
					'#fc8452', '#9a60b4', '#ea7ccc', '#5470c6'
				]
				let tmdata = this.tjTmlist[i].data[j]
				if (tmdata.hasXX) {
					let myChart2 = this.$echarts.init(document.getElementById('chart_jie_' + tmdata.id))
					let data = tmdata.tm_content
					const posList = [
						'left',
						'right',
						'top',
						'bottom',
						'inside',
						'insideTop',
						'insideLeft',
						'insideRight',
						'insideBottom',
						'insideTopLeft',
						'insideTopRight',
						'insideBottomLeft',
						'insideBottomRight'
					];
					app.configParameters = {
						rotate: {
							min: -90,
							max: 90
						},
						align: {
							options: {
								left: 'left',
								center: 'center',
								right: 'right'
							}
						},
						verticalAlign: {
							options: {
								top: 'top',
								middle: 'middle',
								bottom: 'bottom'
							}
						},
						position: {
							options: posList.reduce(function(map, pos) {
								map[pos] = pos;
								return map;
							}, {})
						},
						distance: {
							min: 0,
							max: 100
						}
					};
					app.config = {
						rotate: 90,
						align: 'left',
						verticalAlign: 'middle',
						position: 'insideBottom',
						distance: 15,
						onChange: function() {
							const labelOption = {
								rotate: app.config.rotate,
								align: app.config.align,
								verticalAlign: app.config.verticalAlign,
								position: app.config.position,
								distance: app.config.distance
							};
							myChart2.setOption({
								series: [{
										label: labelOption
									},
									{
										label: labelOption
									},
									{
										label: labelOption
									},
									{
										label: labelOption
									}
								]
							});
						}
					};

					const labelOption = {
						show: true,
						position: app.config.position,
						distance: app.config.distance,
						align: app.config.align,
						verticalAlign: app.config.verticalAlign,
						rotate: app.config.rotate,
						formatter: '{c}  {a}',
						fontSize: 12,
						rich: {
							name: {}
						}
					};
					let option = {
						tooltip: {
							trigger: 'axis',
							axisPointer: {
								type: 'shadow'
							}
						},
						grid: {
							bottom: 120
						},
						legend: {
							data: tmdata.tm_content.map(e => {
								return e.tm_title
							})
						},

						xAxis: [{
							type: 'category',
							axisTick: {
								show: false
							},

							data: tmdata.gyears.map(e => {
								return e.gyear
							})
						}],
						yAxis: [{
							type: 'value'
						}],
						series: []




					};
					let i = 0

					let gyear = tmdata.tm_content.map(e => {


						let d = {
							name: e.title,
							type: 'bar',
							barGap: 0,
							label: labelOption,
							itemStyle: {
								color: colorList[i]
							},
							emphasis: {
								focus: 'series'
							},
							data: []
						}

						i++

						let arr = []
						for (let xyear of tmdata.gyears) {
							let dataok = false

							for (let syear of e.gyears) {

								if (syear.gyear == xyear.gyear) {
									arr.push(syear.count)
									dataok = true
									break
								}

								if (dataok) {
									break
								}

							}

						}


						d.data = arr
						//console.log(d.data)
						return d


					})

					if (tmdata.tm_title == "您现在的情况") {
						//console.log(gyear)
					}

					option.series = gyear.reverse()
					//console.log(option.legend)

					myChart2.setOption(option)

				}

			}


		}
	}
</script>

<style scoped="scoped">
	.boxitem {
		width: 220px;
		height: 90px;
		display: inline-block;

		background-size: 100% 100%;
		margin-bottom: 20px;
		color: #4dadea;
		font-size: 16px;
		background-color: #ecf5fc;
		border-radius: 8px;
		position: relative;

	}

	.boxitem img {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 0;
		left: 0;
	}


	.boxdesc {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		position: relative;
		z-index: 1;
	}
</style>
